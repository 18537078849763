<template>
  <div ref="body">
    <custom-header active="" :subActive="current"></custom-header>
    
    
    <div class="content-container ">
      
      <div class="serch-bar flex">
        <div class="serch-box flex align-center">
          <van-icon name="search" />
          <input type="search" v-model="keyword" placeholder="请输入搜索关键词" @keyup.enter="onSearch">
        </div>
        <button class="search-btn" @click="onSearch">搜 索</button>
      </div>
      
      <div class="list">
        <van-empty v-if="records1.length === 0 && records2.length === 0 && records3.length === 0" description="" />
        <template v-else>
          <div class="flex flex-wrap">
            <div class="news-item" v-for="(item) in records1" :key="item.newsId" @click="toNewsDetail(item.newsId)">
              <img :src="item.newsCover" alt=""  class="cover">
              <div>
                <div class="name text-cut2 text-bold">{{ item.newsTitle }}</div>
              </div>
            </div>
            <div class="news-item" v-for="(item) in records2" :key="item.newsId" @click="toNewsDetail(item.newsId)">
              <img :src="item.newsCover" alt=""  class="cover">
              <div>
                <div class="name text-cut2 text-bold">{{ item.newsTitle }}</div>
              </div>
            </div>
            <div class="news-item" v-for="(item) in records3" :key="item.newsId" @click="toNewsDetail(item.newsId)">
              <img :src="item.newsCover" alt=""  class="cover">
              <div>
                <div class="name text-cut2 text-bold">{{ item.newsTitle }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Icon, Empty } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getNewsList } from '@/api/index.js'

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: '',
      keyword: '',
      records1: [],
      records2: [],
      records3: [],
    }
  },
  created() {
    if (this.$route.query.keywords) {
      this.keyword =  decodeURIComponent(this.$route.query.keywords);
    }
    
    this.onSearch();
  },
  methods: {
    onSearch() {
      if (this.keyword) {
        // 0-逛展小程序展会资讯, 1-官网展会资讯, 2-官网品牌资讯, 3-官网论坛资讯
        this.getNews(1);
        this.getNews(2);
        this.getNews(3);
      }
    },
    getNews(type) {
      let params = {
        type, // 0-逛展小程序展会资讯, 1-官网展会资讯, 2-官网品牌资讯, 3-官网论坛资讯
        keyword: this.keyword,
        pageNo: 1,
        pageSize: 20,
        random: false,
      };
      getNewsList(params).then(res => {
        let records = res.resultData.records || [];
        if (type == 1) {
          this.records1 = records;
        } else if (type == 2) {
          this.records2 = records;
        } else {
          this.records3 = records;
        }
      })
    },
    toNewsDetail(id) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          id,
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .content-container {
    width: 375px;
    margin: 10px auto 20px;
    
      .serch-bar {
        margin: 5px auto 15px;
        justify-content: center;
        
        .serch-box {
          width: 280px;
          height: 30px;
          padding: 0 10px;
          margin-right: 10px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 4px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 13px;
            padding-left: 5px;
          }
          
          .van-icon-search {
            font-size: 16px;
            font-weight: bold;
          }
        }
        
        .search-btn {
          width: 65px;
          height: 30px;
          border-radius: 4px;
          padding: 0;
          font-size: 13px;
          background-color: #060211;
          color: #fff;
          border: none;
        }
    
      }

    .list {
      padding: 0 5px;
      
      /deep/ .van-empty__image {
        width: 100%;
        height: auto;
      }
      
      .news-item {
        width: 172px;
        margin: 0 5px 10px;
        padding-bottom: 5px;
        
        .cover {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 4px;
        }
        
        .name {
          width: 100%;
          margin: 10px 0;
          padding: 0 5px;
          font-size: 13px;
          line-height: 1.5em;
        }
        
        .intro {
          font-size: 3.2px;
          padding: 0 2px;
          margin-bottom: 2px;
        }
        
      }
      
    }
  }
}

@media screen and (min-width: 1024px) {
  .content-container {
    width: 280px;
    margin: 10px auto ;
    
    .serch-bar {
      width: 150px;
      margin: 0 auto 20px;
      
      .serch-box {
        width: 110px;
        height: 10px;
        line-height: 10px;
        padding: 0 3px;
        margin-right: 2px;
        box-sizing: border-box;
        background-color: #F7F8FA;
        border-radius: 1px;
        overflow: hidden;
        
        .van-icon-search {
          font-size: 4.8px;
          font-weight: bold;
        }
        
        input {
          width: 100%;
          border: none;
          background-color: transparent;
          font-size: 3.2px;
          padding-left: 2px;
          font-family: 'SourceHanSansCN-Medium';
          
          &::placeholder {
            font-family: 'SourceHanSansCN-Light';
          }
        }
      }
      
      .search-btn {
        background-color: #060211;
        color: #fff;
        border: none;
        width: 30px;
        height: 10px;
        border-radius: 1px;
        padding: 0;
        font-size: 3px;
        cursor: pointer;
      }
    }
    
    .list {
      
      /deep/ .van-empty__image {
        width: 20%;
        height: auto;
      }
      
      .news-item {
        width: 60px;
        margin: 0 5px 6px;
        padding-bottom: 2px;
        border-radius: 1px;
        
        &:hover {
          box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
          cursor: pointer;
          
          .cover {
            border-radius: 1px 1px 0 0;
          }
        }
        
        .cover {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 1px;
          background-size: auto 100%;
          background-position: center;
        }
        
        .name {
          width: 100%;
          padding: 0 2px;
          margin: 2px 0;
          box-sizing: border-box;
          margin-bottom: 1px;
          font-size: 3px;
        }
        
        .intro {
          font-size: 3.2px;
          padding: 0 2px;
          margin-bottom: 2px;
        }
        
      }
      
    }
  }
  
}

</style>